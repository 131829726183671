@font-size-large: 20px;
@font-size-medium: 18px;
@font-size-small: 13px;

@accent-color: rgb(217, 224, 33);
@text-color: rgb(80, 80, 80);
@text-color-secondary: rgb(120, 120, 120);
@secondary-color: rgb(0, 0, 255);

html,
.portfolio {
  // background-color: @accent-color;
  font-family: "helvetica";
}

.flex {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-items: left;
  margin-left: 50px;
}

.introduction {
  margin-top: 40px;
  margin-bottom: 40px;
  margin-right: 10px;

  max-width: 9900px;
  overflow-wrap: break-word;

  font-size: @font-size-large;
  color: @text-color;

  & .highlighted {
    background-color: @accent-color;
  }
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
