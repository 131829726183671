@import url("https://fonts.googleapis.com/css?family=IBM+Plex+Mono:100,200,300,400,500,600,700,800");
@import "./index.less";

& .text::before {
  content: "";
  display: inline-block;
  height: 1em;
  width: 1em;
  vertical-align: middle;
  background-image: url(./assets/svgs/arrow.svg);
  transform: rotate(90deg);

  background-size: contain;
  background-repeat: no-repeat;
  margin-right: 0.5em;
}

.background-pixels {
  // display in bottom left corner
  position: fixed;
  bottom: 0;
  left: 0;
  width: 500px;
  height: 150px;

  background: url("./assets/svgs/pixels.svg") bottom left no-repeat;
  background-position-x: calc(50px);
  background-position-y: calc(100% - 20px);
  background-size: 500px;
  // on hover make background color red
  &:hover {
    transform: rotate(180deg);
    transform: scale(-1, 1);
  }
}

.background-arrows {
  content: "";

  // two arrows, one above the other, make them show in the top
  // right of the page as backgrounds with no repeat
  background-image: url(./assets/svgs/wide-arrow.svg);
  background-repeat: no-repeat;
  background-position: bottom right, bottom right;
  background-size: 200px;
  background-position-y: 25px, 150px;
  background-position-x: calc(100% - 25px), calc(100% - 25px);
  // transform: rotate(-45deg);

  position: fixed;
  top: 0;
  right: 0;
  width: 250px;
  height: 100%;
  z-index: -100;
  // only show if screen is wider than 1000px
  @media (max-width: 1000px) {
    display: none;
  }

  &:hover {
    animation: fadeRise 2s infinite;
  }
}

// fade in and rise up 100px, then fade out
@keyframes fadeRise {
  0% {
    opacity: 0;
    transform: translateY(200px);
  }
  100% {
    opacity: 1;
    transform: translateY(0px);
  }
}

@keyframes shiftLeftAndRight {
  0% {
    background-position-x: calc(50px);
    background-position-y: calc(100% - 20px);
  }
  50% {
    background-position-x: calc(50px + 500px);
    background-position-y: calc(100% - 20px);
  }
  100% {
    background-position-x: calc(50px);
    background-position-y: calc(100% - 20px);
  }
}

@keyframes mirror {
  0% {
    transform: scale(1, 1);
  }
  50% {
    transform: scale(-1, 1);
  }
}
