@import "../index.less";

.projects {
  display: inline-block;
  margin-left: 0;
  font-size: @font-size-medium;
  color: @secondary-color;
  width: 300px;

  & ul {
    display: inline-block;

    list-style-type: none;
    margin: 0; /* To remove default bottom margin */
    padding: 0; /* To remove default left padding */

    & li {
      text-decoration: underline;

      padding-bottom: 3px;
    }
    & li:hover {
      color: @accent-color;
      margin-left: 8px;
      padding-left: 2px;
      padding-right: 2px;
      // inner border 1px
      border: 1px solid @accent-color;
    }

    & .selected {
      &::before {
        content: "*";
        display: inline-block;
        height: 0.7em;
        width: 0.8em;
        vertical-align: middle;
        transform: rotate(deg);

        background-size: contain;
        background-repeat: no-repeat;
        margin-right: 0.25em;
      }
    }

    & li:not(:last-child) {
      margin-bottom: px;
    }
  }
}

.content-switcher {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  row-gap: 20px;
  margin-left: 40px;
  color: @text-color-secondary;
}

.content {
  font-size: @font-size-small;
  max-width: 800px;
  color: @text-color;
}

.content-text {
  max-width: 500px;
}

.blog-container {
  max-height: 100vh;
}
.blog-post {
  color: @text-color;
}

.title {
  margin-bottom: 10px;
}

a {
  color: @secondary-color;
  text-decoration: none;
  //underline in blue
  &:hover {
    text-decoration: underline;
  }
}

.link {
  &::before {
    content: "🔗";

    margin-right: 2px;
  }

  a {
    color: @secondary-color;
    text-decoration: underline;
  }
}

.blog-images {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 5px;
  margin-top: 20px;

  & img {
    width: 350px;
    height: 350px;
    object-fit: cover;

    &.small {
      width: 350px;

      &.square {
        height: 350px;
      }
    }
    &.nozoom {
      object-fit: contain;
    }
  }
}

.ascii {
  color: @text-color;
  font-family: "IBM Plex Mono";

  font-size: 10px;
  line-height: 80%;
}

.clickable {
  cursor: pointer;
}
